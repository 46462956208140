<template>
    <site-layout class="register" :top="true">
        <nav-bar />
        <div class="main">
            <router-view/>
        </div>
    </site-layout>
</template>

<script>
    import SiteLayout from "@/layout/SiteLayout";
    import NavBar from '@/components/NavBar'
    export default {
        name: "Index",
        components: {SiteLayout, NavBar},
    }
</script>

<style scoped>

</style>
